<template>
<v-app>
   <div>
     <div class="row">
      
    <div style="margin-left:10px" class="col-md-5">
      
      <h6> <strong> Filtrer par :</strong></h6>
                    <div style="color:black" class="form-group">
                     
                      <select
                        id="inputState"
                        class="form-control"
                       v-model="selected"
                        required
                      >
                      <option  value="animatrice">Animatrices</option>
                      <option  value="client">Clients</option>
                       <option  value="produits">Produits</option>
                       <option  value="marque">Marques</option>
                       <option  value="none">None</option>
                    
                      </select>
                    </div>
                  </div>
   <div  class="col-md-6">
                      <div class="row">
                         <div style="margin-top:29px" v-show="selected=='marque'" class="col-md-6 animate__animated animate__bounceInRight">
                    <div class="form-group">
                     
                       <select
                          id="inputState"
                          class="form-control"
                          v-model="marque"
                          required
  
                        >
                         <option  value="all">all</option>
                          <option
                            v-for="user in marques"
                            :key="user"
                            v-bind:value="user"
                          >
                            {{ user }}
                          </option>
                        </select>
                    </div>
                  </div>
          <div  style="margin-bottom:5px" v-show="selected=='marque'" class="col-md-2 animate__animated animate__bounceInRight">
             <v-btn
  
   
              style="margin-top:37px"
              depressed
                    color="primary"
                @click="filtermarque()"
              >filtrer
              </v-btn>
              </div>   
       
       
       
    <div style="margin-top:29px" v-show="selected=='animatrice'" class="col-md-6 animate__animated animate__bounceInRight">
                    <div class="form-group">
                     
                      <select
                        id="inputState"
                        class="form-control"
                       v-model="selecteduser"
                        required
                      >
                      <option  value="all">all</option>
                      <option
                          v-for="user in users"
                          :key="user.color_animatrice"
                          v-bind:value="user._id"
                        >
                        <i
                      class="mdi mdi-delete icone text-danger"
                      style="color: red"
                      
                    />
                        <a >{{ user.name }}</a>
                        </option>
                      </select>
                    </div>
                  </div>
          <div  style="margin-bottom:5px" v-show="selected=='animatrice'" class="col-md-2 animate__animated animate__bounceInRight">
             <v-btn
  
   
              style="margin-top:37px"
              depressed
                    color="primary"
                @click="filter()"
              >filtrer
              </v-btn>
              </div>    


              
              <div style="margin-top:27px" v-show="selected=='client'" class="col-md-6 animate__animated animate__bounceInRight">
                    <div class="form-group">
                     
                      <select
                        id="inputState"
                        class="form-control"
                       v-model="selectedzone"
                        required
                      >
                      <option  value="all">all</option>
                      <option
                            v-for="client in clients"
                            :key="client._id"
                            v-bind:value="client.name"
                          >
                            {{ client.name }}
                          </option>
                      </select>
                    </div>
                  </div>
          <div  style="margin-bottom:5px" v-show="selected=='client'" class="col-md-2 animate__animated animate__bounceInRight">
             <v-btn
  
   
              style="margin-top:37px"
              depressed
                    color="primary"
                @click="filterclient()"
              >filtrer
              </v-btn>
              </div>     


                  <div style="margin-top:27px" v-show="selected=='produits'" class="col-md-6 animate__animated animate__bounceInRight">
                    <div class="form-group">
                     
                      <select
                        id="inputState"
                        class="form-control"
                       v-model="selectedzone"
                        required
                      >
                      <option  value="all">all</option>
                      <option
                            v-for="produit in produits"
                            :key="produit._id"
                            v-bind:value="produit.reference"
                          >
                            {{ produit.reference }}
                          </option>
                      </select>
                    </div>
                  </div>
          <div  style="margin-bottom:15px" v-show="selected=='produits'" class="col-md-2 animate__animated animate__bounceInRight">
             <v-btn
  
   
              style="margin-top:37px"
              depressed
                    color="primary"
                @click="filterproduit()"
              >filtrer
              </v-btn>
              </div>  
  </div>
                    </div>
    </div>
    
        <br>
        <br>
       <div class="text-center animate__animated animate__bounceInRight">


                                           <button
                                           id="btn"
                                          style="margin-right:10px"
                                    type="button"
                                     class="btn btn-success"
                                     @click='updatecalander'

                                >
                                 Drag and Drop Update <i style="font-size:20px;margin-left:5px;color:#f06656" class="mdi mdi-sync"></i>
                                </button>
                                
                                           <button

                                    type="button"
                                    class="btn btn-primary"
                                     @click.prevent='onRefreshLayout'

                                >
                                    Actualiser le calendrier <i style="font-size:20px;margin-left:5px;margin-top:50px;color:#7df5cd" class="mdi mdi-playlist-check"></i>
                                </button>
                               
                                </div>
                                <br>
        <div  id='app'>
     
            <div id='container'>
                <ejs-schedule  :popupOpen='onPopupOpen ' ref="ScheduleObj"   id='Schedule' width='100%' height='550px' :eventSettings='eventSettings' :selectedDate='selectedDate' :currentView='currentView'>
                    <e-views>
                        <e-view option='Week'></e-view>
                        <e-view option='Month'></e-view>
                        <e-view option='TimelineWeek'></e-view>
                        <e-view option='TimelineMonth'></e-view>
                        <e-view option='Agenda'></e-view>
                    </e-views>
                    <e-resources>
                        <e-resource field='OwnerId' title='Owner' name='Owners' :allowMultiple='allowMultiple' :dataSource='resourceDataSource' textField='OwnerText' idField='Id' colorField='OwnerColor'>
                        </e-resource>
                    </e-resources>
                </ejs-schedule>
            </div>
            
    
        </div>
        <div data-app>
                  <v-row justify="center">
  

    <v-dialog
     class="mx-auto animate__animated animate__bounceOutUp"
      v-model="dialog"
      max-width="590"
      max-height="100" 
      
    >
      <v-card
       class="mx-auto"
    
   
    >
      <v-toolbar
          
          color="#F0F0F0F9"
        >
          <!-- <v-btn
            icon
           
            @click="dialog = false"
          >
            <v-icon style="color:red;">mdi-close</v-icon>
          </v-btn> -->
          <v-toolbar-title> <strong>{{ text.Subject }}</strong></v-toolbar-title>

            <i 
                        class="mdi mdi-delete icone"  style="color:red;margin-left:280px;font-size:150px"
                         @click="deletemarketing(text._id)"
                      />
                      <i 
                         class="mdi mdi-pen icone text-success" style="color:green"
                         @click="updatemarketing(time)"
                      />
          <v-spacer></v-spacer>
       
        </v-toolbar>
         
      
        <v-card-text>
            <!-- <h6 style="color:black"> {{ text.Subject }}</h6> -->
            <div v-show="show" >
                 <div class="my-4 text-subtitle-1">
          <i
                          class="mdi mdi-calendar-check"
                          
                          style="font-size:22px;margin-top:30px;color:blue"
                        >
                        </i> <v-chip style="font-size:13px;margin-bottom:10px;"><strong>{{time}}</strong></v-chip> 
                        <br> 
                         <i
                          class="mdi mdi-map-marker-radius "
                          
                          style="font-size:22px;margin-top:30px;color:green"
                        >
                        </i> <v-chip style="font-size:13px;margin-bottom:10px;"> <strong>{{text.Location}}</strong></v-chip> 

                         <br> 
                         <i
                          class="mdi mdi-cart"
                          
                          style="font-size:22px;margin-top:30px;color:red"
                        >
                        </i> <v-chip style="font-size:13px;margin-bottom:10px;"> <strong>{{text.Produits}}</strong></v-chip>   
      </div>
      <hr>
        <GmapMap
      ref="map"
      :center="center"
      :zoom="zoom"
      style="width: 100%; height: 300px;"
    >
      <GmapMarker
        :key="index"
        v-for="(m, index) in markers"
        :position="m.position"
        @click="center = m.position"
      />
    </GmapMap>
            </div>
            <div class="animate__animated animate__pulse" v-show="showdate">
                <div class="col-md-12">
                      <div class="form-group">
                        
                        <v-date-picker  
         max-width="90" :available-dates="availableDates" is-inline  v-model="picker"></v-date-picker>
                        
                    
                      </div>
                    </div>
            </div>
        
    
        </v-card-text>

        <v-card-actions v-show="show">
          <v-spacer></v-spacer>

          <v-btn
           
            text
            @click="dialog = false"
          >
             <span style="color:red">fermer</span>
          </v-btn>

          
        </v-card-actions>
        
        <v-card-actions v-show="showdate">
          <v-spacer></v-spacer>

          <v-btn
           
            text
            @click="annuler"
          >
             <span style="color:red">Annuler</span>
          </v-btn>
             <v-btn
           
            text
            @click="update(text._id)"
          >
             <span style="color:green">Update</span>
          </v-btn>

          
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
    
</div>
  
    </div>
</v-app>
   
</template>

<script>
    import { HTTP } from "@/axios";
    import moment from "moment";
    import Vue from 'vue';
    
    import { SchedulePlugin, Week, Month, Agenda, TimelineViews, TimelineMonth,DragAndDrop } from '@syncfusion/ej2-vue-schedule';

    Vue.use(SchedulePlugin);

    export default {
        data () {
            return {
               availableDates: { 
        start: new Date(), 
        end: new Date(new Date().setDate(new Date().getDate() + 7)) 
      },
             
              marques: ['foo', 'bar', 'fizz', 'buzz'],
                  selected:'',
                selecteduser:'',
                selectedzone:'',
                marque:'',
               picker: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
              show:true,
              showdate:false,
                  btn:false,
                 zoom:14,
                center: { lat: 36.794895, lng: 10.170715 },
                currentPlace: null,
                markers: [],
                clients: [],
                eventsoriginal:
                 [
                  
                ],
                 users: [],
                 produits:[],
                 dialog: false,
                 multiLine: true,
                snackbar: false,
                time:'',
                text: ``,
                width: '100%',
                height: '550px',
                currentView: 'Month',
                views: ['Week', 'Month', 'TimelineWeek','TimelineMonth', 'Agenda'],
                selectedDate: new Date(Date.now()),
                allowMultiple: true,
                resourceDataSource: [
                    //  { OwnerText: 'Nancy', Id: '1', OwnerColor: '#ffaa00' },
                    
                ],
                eventSettings: { 
                  dataSource:
                [
                  //  {
                  //        Id: 56668877,
                  //        Subject: 'zamer',
                  //       StartTime: new Date('2022-10-13'),
                  //        EndTime:new Date('2022-10-13'),
                  //      IsAllDay: false,
                  //      Location: 'London',
                       
              
                  //        RecurrenceRule:"FREQ=WEEKLY;BYDAY=WE;INTERVAL=1;COUNT=4;"
                
                  //    }, 
                     
      
                 ],
                 },
           
            }
        },
        provide: {
            schedule: [Week, Month, Agenda, TimelineViews, TimelineMonth,DragAndDrop]
        },
           mounted: function () {
           
            $("#js-licensing").hide();
            var $body = $('body');
// $body.on('mousedown', function (evt) {
//   $body.on(' mousemove', function handler(evt) {
//     if (evt.type === 'mousemove') {
//       console.log('test')
//     } else {
//       console.log('test2');
//       $("#btn").show();
//       this.btn=true;
//     }
//     $body.off('mouseup mousemove', handler);
//   });
// });
                
                  },
          created() {
    this.getusers();
     this.getclients();
      //this.getevents();
      this.getproduits();
    this.geteventsall();
  },
        methods: {
              onRefreshLayout: function () {
                let scheduleObj = document.getElementById('Schedule').ej2_instances[0];
                scheduleObj.refreshLayout();
            },
            filter(){
      
        if(this.selecteduser=="")
        {
          var scheduleObj = document.getElementById('Schedule').ej2_instances[0];
           scheduleObj.eventSettings.dataSource=this.eventsoriginal;
        }
       else if(this.selecteduser=="all")
        {
           var scheduleObj = document.getElementById('Schedule').ej2_instances[0];
           scheduleObj.eventSettings.dataSource=this.eventsoriginal;
        }
        else{
             var scheduleObj = document.getElementById('Schedule').ej2_instances[0];
           scheduleObj.eventSettings.dataSource=this.eventsoriginal;
          // this.eventsbase= this.eventsbaseall;
          var scheduleObj = document.getElementById('Schedule').ej2_instances[0];
        let filter = 
 scheduleObj.eventSettings.dataSource.filter(d => 
    d.OwnerId == this.selecteduser
)

  this.eventsbase=filter;
   var scheduleObj = document.getElementById('Schedule').ej2_instances[0];
    scheduleObj.eventSettings.dataSource=filter;

        }

      },
               filterclient(){
      
        if(this.selectedzone=="")
        {
          var scheduleObj = document.getElementById('Schedule').ej2_instances[0];
           scheduleObj.eventSettings.dataSource=this.eventsoriginal;
        }
       else if(this.selectedzone=="all")
        {
           var scheduleObj = document.getElementById('Schedule').ej2_instances[0];
           scheduleObj.eventSettings.dataSource=this.eventsoriginal;
        }
        else{
             var scheduleObj = document.getElementById('Schedule').ej2_instances[0];
           scheduleObj.eventSettings.dataSource=this.eventsoriginal;
          // this.eventsbase= this.eventsbaseall;
          var scheduleObj = document.getElementById('Schedule').ej2_instances[0];
        let filter = 
 scheduleObj.eventSettings.dataSource.filter(d => 
    d.Location == this.selectedzone
)

  this.eventsbase=filter;
   var scheduleObj = document.getElementById('Schedule').ej2_instances[0];
    scheduleObj.eventSettings.dataSource=filter;

        }

      },
                 filterproduit(){
      
        if(this.selectedzone=="")
        {
          var scheduleObj = document.getElementById('Schedule').ej2_instances[0];
           scheduleObj.eventSettings.dataSource=this.eventsoriginal;
        }
       else if(this.selectedzone=="all")
        {
           var scheduleObj = document.getElementById('Schedule').ej2_instances[0];
           scheduleObj.eventSettings.dataSource=this.eventsoriginal;
        }
        else{
             var scheduleObj = document.getElementById('Schedule').ej2_instances[0];
           scheduleObj.eventSettings.dataSource=this.eventsoriginal;
          // this.eventsbase= this.eventsbaseall;
          var scheduleObj = document.getElementById('Schedule').ej2_instances[0];
        let filter = 
 scheduleObj.eventSettings.dataSource.filter(d => 
    d.Produits.includes(this.selectedzone)
)

  this.eventsbase=filter;
   var scheduleObj = document.getElementById('Schedule').ej2_instances[0];
    scheduleObj.eventSettings.dataSource=filter;

        }

      },
                filtermarque(){
      
        if(this.marque=="")
        {
          var scheduleObj = document.getElementById('Schedule').ej2_instances[0];
           scheduleObj.eventSettings.dataSource=this.eventsoriginal;
        }
       else if(this.marque=="all")
        {
           var scheduleObj = document.getElementById('Schedule').ej2_instances[0];
           scheduleObj.eventSettings.dataSource=this.eventsoriginal;
        }
        else{
             var scheduleObj = document.getElementById('Schedule').ej2_instances[0];
           scheduleObj.eventSettings.dataSource=this.eventsoriginal;
          // this.eventsbase= this.eventsbaseall;
          var scheduleObj = document.getElementById('Schedule').ej2_instances[0];
        let filter = 
 scheduleObj.eventSettings.dataSource.filter(d => 
    d.marque == this.marque
)

  this.eventsbase=filter;
   var scheduleObj = document.getElementById('Schedule').ej2_instances[0];
    scheduleObj.eventSettings.dataSource=filter;

        }

      },
              onPopupOpen: function(args) {
                     if (args.type === 'QuickInfo') {
                           
                            console.log(args.data)
                            args.cancel = true;
                            this.text=args.data
                            this.markers=[];
                                    const marker = {
                              lat: Number(args.data.lat),
                              lng: Number(args.data.lng),
                            };
                          
                            this.markers.push({ position: marker });
                              this.center = {
                              lat: Number(args.data.lat),
                              lng: Number(args.data.lng),
                            };
                            
                            
                        let time= moment(String(args.data.StartTime)).format("YYYY-MM-DD")
                      this.time=time;
                      if(args.data.lat)
                     { this.dialog = true  }     

            }
                  
                  else if (args.type === 'Editor') {
                    console.log('modal event')
                     args.cancel = true;}
               


                     },
                     
      getproduits() {
        HTTP
          .get("produits/getproduits")
          .then((response) => {
           
            this.produits = response.data;
          })
          .then((err) => console.log(err));
      },
                         getclients() {
        HTTP
          .get("annonces/getclientss")
          .then((response) => {
           
            this.clients = response.data;
          })
          .then((err) => console.log(err));
      },
               getusers() {
                 
      HTTP.get("annonces/getanimatrice")
        .then((response) => {
          
          this.users = response.data;
             response.data.forEach((element, index, array) => {
             this.resourceDataSource.push( {
                  OwnerText: element.name,
                   Id: element._id,
                  OwnerColor: element.color_animatrice });
        
      
          });
                   
                   


        })
        .then((err) => console.log(err));
    },
          getevents() {
             var data_array=[];
      HTTP.get("marketings/getmarketings")
        .then((response) => {
          
          response.data.forEach((element, index, array) => {
           
              var my_object = {};
                         my_object.Id =element._id;
                         my_object.Location=element.client_id["name"];
                         my_object.Subject =element.user_id["name"];
                      
                         my_object.StartTime =new Date(element.date_debut);
                         my_object.EndTime =new Date(element.date_fin);
                         my_object.RecurrenceRule=element.selected;
                          my_object.OwnerId=element.user_id["_id"];
                          my_object.lat=element.client_id["latitude"];
                          my_object.lng=element.client_id["longitude"];
                           my_object.color=element.user_id["color_animatrice"];
                         data_array.push(my_object);
          });
              var scheduleObj = document.getElementById('Schedule').ej2_instances[0];
                      scheduleObj.addEvent(data_array);
                      console.log('ggg');
                      console.log(scheduleObj.eventSettings.dataSource)
                    
         
          
        })
        .then((err) => console.log(err));
    },
         geteventsall() {
             var data_array=[];
      HTTP.get("marketings/getmarketingsevents")
        .then((response) => {
          
           response.data.forEach((element, index, array) => {
          
                          data_array.push(element);
                          this.eventsoriginal.push(element);
           });
             
               var scheduleObj = document.getElementById('Schedule').ej2_instances[0];
                       scheduleObj.addEvent(data_array);
                       console.log('ggg');
                       console.log(scheduleObj.eventSettings.dataSource)
                    
       
          
        })
        .then((err) => console.log(err));
    },
    updatecalander(){
        var scheduleObj = document.getElementById('Schedule').ej2_instances[0];
           
            if(scheduleObj.eventSettings.dataSource.length !==0){
              
          Swal.fire({
          title: "Êtes-vous sûrs ?",
          text: "Vous voulez Mise à jour le calendrier",
          icon: "warning",
          cancelButtonText: "Annuler",
          showCancelButton: true,
          confirmButtonColor: "#3e884f",
          cancelButtonColor: "#d33",
          confirmButtonText: "Oui, valider!",
        }).then((result) => {
          if (result.isConfirmed) {
             var scheduleObj = document.getElementById('Schedule').ej2_instances[0];
            let marketingg =scheduleObj.eventSettings.dataSource

        HTTP.put("marketings/updatecalander/", marketingg).then(
        (res) => {
          if (res.status === 200) {
            Toast.fire({
              icon: "success",
              title: "calander modifié",
            });
            location.reload();
          }
        },
        (err) => {
          this.error = err.response.data.error;
          console.log(this.error);
        }
      );
          }
        });

            }
      
       //////////////////////
     
    },
     deletemarketing(id) {
        Swal.fire({
          title: "Êtes-vous sûrs ?",
          text: "Vous voulez supprimé l'marketing",
          icon: "warning",
          cancelButtonText: "Annuler",
          showCancelButton: true,
          confirmButtonColor: "#3e884f",
          cancelButtonColor: "#d33",
          confirmButtonText: "Oui, valider!",
        }).then((result) => {
          if (result.isConfirmed) {
          HTTP
              .delete("marketings/deletemarketingsingle/" + id)
              .then((response) => {
  
                Toast.fire({
                  position: "top-center",
                  icon: "success",
                  title: "marketing supprimé",
                });
  
               location.reload();
              });
          }
        });
      },
      updatemarketing(id){
        this.show=false;
        this.showdate=true;
        this.picker=id;
      },
      annuler(){
           this.show=true;
        this.showdate=false;
        this.dialog = false;

      },
      update(id){
          let marketing ={
            id:id,
            date:this.picker

          }

        HTTP.put("marketings/updatemarketingsingle/", marketing).then(
        (res) => {
          if (res.status === 200) {
            Toast.fire({
              icon: "success",
              title: "marketing modifié",
            });
            location.reload();
          }
        },
        (err) => {
          this.error = err.response.data.error;
          console.log(this.error);
        }
      );

      }
   
    }
    }
</script>
   
<style>
@import '../../../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-vue-schedule/styles/material.css';

.schedule-cell-dimension.e-schedule .e-vertical-view .e-date-header-wrap table col,
.schedule-cell-dimension.e-schedule .e-vertical-view .e-content-wrap table col {
    width: 100px;
}

.schedule-cell-dimension.e-schedule .e-vertical-view .e-time-cells-wrap table td,
.schedule-cell-dimension.e-schedule .e-vertical-view .e-work-cells {
    height: 100px;
}

.schedule-cell-dimension.e-schedule .e-month-view .e-work-cells,
.schedule-cell-dimension.e-schedule .e-month-view .e-date-header-wrap table col {
    width: 100px;
}

.schedule-cell-dimension.e-schedule .e-month-view .e-work-cells {
    height: 100px;
}
.e-toolbar-item .e-add .e-overflow-show{
    display:none;
}
#Schedule{
    height:550px ;
    width:100% ;

}
.vue-map-container,
.vue-map-container .vue-map {
    width: 100%;
    height: 100%;
}
</style>

